<template>
  <el-header
    class="header_ display_flex align_item_center justify_content_space_between"
  >
    <section class="display_flex align_item_center">
      <section class="logo_container">
        <img src="../../assets/img/logo.png" class="logo" alt="" />
        <h1 class="sys_name">熊猫热点</h1>
      </section>
      <section class="btn_group">
        <el-button
          v-if="is_mcn == 1"
          :type="pageType === '3' ? 'info' : 'text'"
          class="black_btn"
          @click="changePageType('3')"
          >MCN</el-button
        >
        <el-button
          :type="pageType === '1' ? 'info' : 'text'"
          class="black_btn"
          @click="changePageType('1')"
          >媒体达人</el-button
        >
        <el-button
          :type="pageType === '2' ? 'info' : 'text'"
          class="black_btn"
          @click="changePageType('2')"
          >基础设置</el-button
        >
      </section>
    </section>
    <span @click="logout()">
      <el-avatar> Adm </el-avatar>
    </span>
  </el-header>
</template>
<script>
export default {
  data() {
    return {
      pageType: "1",
    };
  },
  created() {
    this.pageType = localStorage.getItem("pageType") || "1";
    this.is_mcn = localStorage.getItem("is_mcn");
  },
  methods: {
    changePageType(v) {
      this.pageType = v;
      localStorage.setItem("pageType", this.pageType);
      if (v === "1") {
        localStorage.setItem("activePath", "tiktokSearch");
        location.href = "/tiktokSearch";
      } else if (v === "3") {
        localStorage.setItem("activePath", "summaryReport");
        location.href = "/summaryReport";
      } else {
        localStorage.setItem("activePath", "classifySetting");
        location.href = "/classifySetting";
      }
    },
    logout() {
      this.$confirm("确定退出？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.removeItem("token");
          this.$cookies.delete("hotpots_username_from_rocket");
          this.$cookies.delete("hotpots_password_from_rocket");
          this.$cookies.delete("hotpots_token_from_rocket");
          this.$router.push("/");
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.header_ {
  padding: 0;
  padding-right: 20px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .btn_group {
    margin-left: 20px;
    .black_btn {
      color: #262b2e;
      padding: 10px 20px;
      &.el-button--info {
        color: #fff;
        background: #262b2e;
        border-color: #262b2e;
      }
    }
  }
  .el-avatar {
    margin-top: 10px;
    cursor: pointer;
  }
}
</style>
