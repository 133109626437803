<template>
  <div class="navComp">
    <el-menu
      :default-active="activePath"
      text-color="#FFFFFF"
      background-color="#333743"
      v-if="pageType === '3'"
    >
      <div v-for="nav in navs" :key="nav.name">
        <template v-if="nav?.children && nav?.children.length">
          <el-submenu :index="nav.name" v-show="nav.showOnNav">
            <template slot="title">
              <i :class="nav.icon"></i> {{ nav.meta.page_name }}
            </template>
            <el-menu-item
              v-for="menu in nav.children"
              :key="menu.name"
              :index="menu.name"
              @click="navTo(`${menu.name}`)"
              v-show="menu.showOnNav"
              >{{ menu.meta.page_name }}</el-menu-item
            >
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item
            :index="nav.name"
            @click="navTo(`${nav.name}`)"
            v-show="nav.showOnNav"
          >
            <i :class="nav.icon"></i> {{ nav.meta.page_name }}
          </el-menu-item>
        </template>
      </div>
    </el-menu>
    <el-menu :default-active="activePath" background-color="#333743" v-else>
      <template>
        <el-menu-item
          v-for="nav in navs"
          :key="nav.name"
          :index="nav.name"
          @click="navTo(`${nav.name}`)"
          v-show="nav.showOnNav"
        >
          <i :class="nav.icon"></i> {{ nav.meta.page_name }}
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: "navComp",
  data() {
    return {
      activePath: "/",
      navs: [],
      pageType: "1",
    };
  },
  created() {
    this.pageType = localStorage.getItem("pageType");
    let navs = this.$router.options.routes.filter(
      (item) => item.name === "Layout"
    );
    this.navs = navs[0].children;

    this.activePath =
      localStorage.getItem("activePath") ||
      (this.pageType === "1"
        ? "tiktokSearch"
        : this.pageType === "3"
        ? "mcn"
        : "classifySetting");
  },
  methods: {
    navTo(url) {
      localStorage.setItem("activePath", url);
      this.$router.push(`/${url}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.navComp {
  width: 200px;
  box-shadow: rgb(197, 197, 197, 0.1) 0px 2px 4px 0px;
  height: 100%;
  .el-menu {
    border-right: 0;
    height: 100%;
    .el-menu-item,
    :deep(.el-submenu__title) {
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      &:focus,
      &:hover,
      &.is-active {
        background-color: #131b2b !important ;
      }
      i {
        color: #ffffff;
        margin-right: 10px;
      }
    }
    .el-submenu .el-menu-item {
      padding-left: 54px !important;
    }
  }
}
</style>
