<template>
  <el-container style="height: 100%; background: #f5f7fb" direction="vertical">
    <headerComp></headerComp>
    <el-container
      style="width: 100%; height: calc(100vh - 60px)"
      direction="horizontal"
    >
      <el-aside width="200px">
        <navComp></navComp>
      </el-aside>
      <div class="view-area">
        <router-view id="view-area-content" />
      </div>
    </el-container>
  </el-container>
</template>
<script>
import headerComp from "@/views/layout/header";
import navComp from "@/views/layout/navComp.vue";
export default {
  inject: ["reload"], //在子组件中注入在父组件中出创建的属性
  components: {
    headerComp,
    navComp,
  },
  created() {
    console.log(this.$router);
  },
};
</script>
<style lang="scss" scoped>
.view-area {
  background: #f5f7fb;
  position: relative;
  overflow: auto;
  width: 100%;
  // height: calc(100% - 60px);
  height: 100%;
  & > div {
    min-width: 900px;
    overflow: scroll;
    padding: 20px;
  }
}
</style>